var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('VehicleAddEdit',{attrs:{"es-id":_vm.addEdit.esId,"stepper-mode":_vm.addEdit.stepperMode,"stepper-step":_vm.addEdit.stepperStep,"primary-key":_vm.addEdit.primaryKey,"primary-key-lock":_vm.addEdit.primaryKeyLock,"raw-data":_vm.addEdit.rawData,"vehicleType":_vm.vehicleType,"busy":_vm.addEdit.busy},on:{"refresh":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}}),_c('google-map-modal-alt',{attrs:{"marker-is-updatable":false,"variant":"vehicle","title":"Vehicle On The Map","subtitle":_vm.getMapVehicleSubtitle}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"flex flex-wrap items-center justify-between px-6 mb-4 content-spacing"},[(_vm.isAddAnother)?_c('div',[_c('title-plus',{staticClass:"mb-5",attrs:{"title":_vm.pageTitle},on:{"plus":_vm.addAnother}})],1):_c('div',[(_vm.isShowOnlyPageTitle)?_c('div',[_c('oto-page-title',{attrs:{"title":_vm.pageTitle}})],1):_c('div',{staticClass:"flex items-center"},[_c('oto-page-title',{attrs:{"title":_vm.pageTitle}}),(_vm.$acl.canCreate('vehicles'))?_c('div',{staticClass:"block"},[_c('div',{staticClass:"tooggle-btn",on:{"click":_vm.toggleDropdown}},[_c('img',{attrs:{"src":require("@/assets/img/multiple-btn.png"),"width":"55","height":"30"}})]),_c('div',{ref:"popoverDropdownRef",staticClass:"absolute float-left py-2 mt-1 text-base text-left list-none bg-white rounded shadow-2xl z-1",class:{
                hidden: !_vm.dropdownPopoverShow,
                block: _vm.dropdownPopoverShow,
              },staticStyle:{"min-width":"12rem"}},[_c('div',{staticClass:"cursor-pointer item-btn",on:{"click":function($event){return _vm.add('single')}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleManagement.addAction.addSingleVehicle' ))+" ")]),_c('div',{staticClass:"cursor-pointer item-btn",on:{"click":function($event){return _vm.add('multiple')}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleManagement.addAction.addMultipleVehicles' ))+" ")])])]):_vm._e()],1)])]),_c('div',{staticClass:"flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row px-5"},[_c('SummaryCard',{attrs:{"title":_vm.$t('components.vehicleManagement.summary.totalVehicles'),"value":_vm.indexMetaData.summary.total,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.vehicleManagement.summary.onRide'),"value":_vm.indexMetaData.summary.on_ride,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.vehicleManagement.summary.idle'),"value":_vm.indexMetaData.summary.idle,"variant":"gray"}})],1),(_vm.$acl.canView('vehicles'))?[_c('FSTable',{attrs:{"fst-id":_vm.fstId,"endpoint":_vm.endpoint,"qso":{
          append: '',
          prepend: '',
        },"headers":_vm.getTableHeaders,"exportFor":"vehicle","isQrCodeDownloadEnabled":true,"isHeaderChecked":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
        var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.getFilteredItems}})],1)]}},{key:"topRight",fn:function(ref){return [(_vm.getActionButtonStatus)?_c('TakeActions',{attrs:{"scope":"vehicle-list"}}):_vm._e()]}},{key:"default",fn:function(ref){
        var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('TCheckbox',{attrs:{"checked":item.isSelected,"name":"selectFromList","variant":"lg","wrapped":""},on:{"change":function($event){return _vm.$store.commit('fsTable/onSelect', {
                        key: itemIndex,
                        value: $event,
                      })}}})],1),_c('FSTableRowItem',{attrs:{"text":item.name,"to":{
                    name: 'ViewVehicleProfile',
                    params: { id: item.id, redirectPath: _vm.path },
                  }}}),_c('FSTableRowItem',[_c('battery-status',{attrs:{"level":item.lock.power_level}})],1),_c('FSTableRowItem',{attrs:{"text":item.lock.lock_id}}),_c('FSTableRowItem',{attrs:{"text":item.qr_code}}),(
                    [
                      'marketplace-vehicles',
                      'marketplace-buyer-vehicles' ].includes(_vm.fstId)
                      ? false
                      : true
                  )?_c('FSTableRowItem',[_c('span',{attrs:{"title":_vm.getUTCAwareTime(item.last_connected_at)},domProps:{"textContent":_vm._s(
                      _vm.getUTCAwareTime(item.last_connected_at, {
                        relative: true,
                      })
                    )}})]):_vm._e(),(
                    [
                      'marketplace-vehicles',
                      'marketplace-buyer-vehicles' ].includes(_vm.fstId)
                      ? false
                      : true
                  )?_c('FSTableRowItem',[_c('span',{attrs:{"title":_vm.getUTCAwareTime(item.last_loc_updated_at)},domProps:{"textContent":_vm._s(
                      _vm.getUTCAwareTime(item.last_loc_updated_at, {
                        relative: true,
                      })
                    )}})]):_vm._e(),_c('FSTableRowItem',[(_vm.getEnabledFlags(item).length > 0)?[_c('div',{staticClass:"flex items-center"},[_vm._l((_vm.getEnabledFlags(item)),function(flag,flagIndex){return [_c('vehicle-flag-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getFlagAlias(flag, item)),expression:"getFlagAlias(flag, item)",modifiers:{"bottom":true}}],key:("vehicle-" + itemIndex + "-flag-" + flagIndex),staticClass:"mr-1",attrs:{"size":"sm","name":flag}})]})],2)]:[_vm._v(" No Flags ")]],2),_c('FSTableRowItem',[_c('HeartbeatStatus',{attrs:{"status":item.heart_beat,"is-available":item.is_available}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"vehicle-map-icon",on:{"click":function($event){return _vm.showGoogleMapModal(item)}}},[_c('i',{staticClass:"fas fa-map"})])]),(['marketplace-vehicles'].includes(_vm.fstId))?_c('FSTableRowItem',[_c('VehicleAssignToBuyerAction',{attrs:{"id":itemIndex,"data":item},on:{"force-sync":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1):_vm._e(),(
                    [
                      'marketplace-vehicles',
                      'marketplace-buyer-vehicles' ].includes(_vm.fstId)
                      ? false
                      : true
                  )?_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('MoreActions',{attrs:{"data":item,"currentItemIndex":itemIndex,"vehicle-type":"scooter","takeActionFrom":"vehicle-list","current-status":item.lock.is_locked,"hibernateStatus":item.general_flags.hibernate_mode,"redirectPath":_vm.path},on:{"edit":function($event){return _vm.onOpenES({ primaryKey: $event.vehicleId })},"change":function($event){item.lock.is_locked = $event},"force-sync":function($event){return _vm.$store.dispatch('fsTable/fetchData')},"delete:failed":_vm.onDeleteFailed,"delete:succeeded":_vm.onDeleteSucceeded}})],1)]):_vm._e()],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('TCheckbox',{attrs:{"checked":item.isSelected,"name":"selectAllFromList","variant":"lg","wrapped":""},on:{"change":function($event){return _vm.$store.commit('fsTable/onSelect', {
                        key: itemIndex,
                        value: $event,
                      })}}})],1),_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":item.name,"to":{
                    name: 'ViewVehicleProfile',
                    params: { id: item.id, redirectPath: _vm.path },
                  }}}),(
                    [
                      'marketplace-vehicles',
                      'marketplace-buyer-vehicles' ].includes(_vm.fstId)
                      ? false
                      : true
                  )?_c('FSTableRowItem',[_c('HeartbeatStatus',{attrs:{"status":item.heart_beat,"is-available":item.is_available}})],1):_vm._e(),(['marketplace-vehicles'].includes(_vm.fstId))?_c('FSTableRowItem',[_c('VehicleAssignToBuyerAction',{attrs:{"id":itemIndex,"data":item},on:{"force-sync":function($event){return _vm.$store.dispatch('fsTable/fetchData')}}})],1):_vm._e(),(['marketplace-buyer-vehicles'].includes(_vm.fstId))?_c('FSTableRowItem',[_c('FSTableRowItem',{attrs:{"text":item.qr_code}})],1):_vm._e(),(
                    [
                      'marketplace-vehicles',
                      'marketplace-buyer-vehicles' ].includes(_vm.fstId)
                      ? false
                      : true
                  )?_c('FSTableRowItem',[_c('div',{staticClass:"flex items-center"},[_c('MoreActions',{attrs:{"data":item,"currentItemIndex":itemIndex,"vehicle-type":"scooter","takeActionFrom":"vehicle-list","current-status":item.lock.is_locked,"hibernateStatus":item.general_flags.hibernate_mode,"redirectPath":_vm.path},on:{"edit":function($event){return _vm.onOpenES({ primaryKey: $event.vehicleId })},"change":function($event){item.lock.is_locked = $event},"force-sync":function($event){return _vm.$store.dispatch('fsTable/fetchData')},"delete:failed":_vm.onDeleteFailed,"delete:succeeded":_vm.onDeleteSucceeded}})],1)]):_vm._e()],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleManagement.table.columns.battery' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('battery-status',{attrs:{"level":item.lock.power_level}})],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.vehicleManagement.table.columns.imei'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.lock.lock_id || "--")+" ")])]),(
                      ['marketplace-buyer-vehicles'].includes(_vm.fstId)
                        ? false
                        : true
                    )?_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleManagement.table.columns.qrCode' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex items-center"},[_vm._v(" "+_vm._s(item.qr_code || "--")+" ")])])]):_vm._e(),(
                      [
                        'marketplace-vehicles',
                        'marketplace-buyer-vehicles' ].includes(_vm.fstId)
                        ? false
                        : true
                    )?_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleManagement.table.columns.lastConnected' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex items-center"},[_c('span',{attrs:{"title":_vm.getUTCAwareTime(item.last_connected_at)},domProps:{"textContent":_vm._s(
                            _vm.getUTCAwareTime(item.last_connected_at, {
                              relative: true,
                            })
                          )}})])])]):_vm._e(),(
                      [
                        'marketplace-vehicles',
                        'marketplace-buyer-vehicles' ].includes(_vm.fstId)
                        ? true
                        : false
                    )?_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleManagement.table.columns.heartBeat' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex items-center"},[_c('HeartbeatStatus',{attrs:{"status":item.heart_beat,"is-available":item.is_available}})],1)])]):_vm._e(),(
                      [
                        'marketplace-vehicles',
                        'marketplace-buyer-vehicles' ].includes(_vm.fstId)
                        ? false
                        : true
                    )?_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleManagement.table.columns.lastLocated' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex items-center"},[_c('span',{attrs:{"title":_vm.getUTCAwareTime(item.last_loc_updated_at)},domProps:{"textContent":_vm._s(
                            _vm.getUTCAwareTime(item.last_loc_updated_at, {
                              relative: true,
                            })
                          )}})])])]):_vm._e(),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.vehicleManagement.table.columns.flags'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex items-center"},[(_vm.getEnabledFlags(item).length > 0)?[_c('div',{staticClass:"flex items-center"},[_vm._l((_vm.getEnabledFlags(
                                item
                              )),function(flag,flagIndex){return [_c('vehicle-flag-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(_vm.getFlagAlias(flag, item)),expression:"getFlagAlias(flag, item)",modifiers:{"bottom":true}}],key:("vehicle-" + itemIndex + "-flag-" + flagIndex),staticClass:"mr-1",attrs:{"size":"sm","name":flag}})]})],2)]:[_vm._v(" No Flags ")]],2)])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.vehicleManagement.table.columns.lastLocation' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"vehicle-map-icon",on:{"click":function($event){return _vm.showGoogleMapModal(item)}}},[_c('i',{staticClass:"fas fa-map"})])])])])])]):_vm._e()]:_vm._e()]})]}}],null,false,1303728520)})]:_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" "+_vm._s(_vm.$t('components.acl.doNotHavePermission'))+" ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }