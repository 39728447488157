<template>
  <div>
    <div class="flex w-auto mt-2 md:items-center md:justify-end md:mt-0">
      <t-dropdown variant="smActionsMedium">
        <template
          v-slot:trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
          }"
        >
          <button
            :class="getButtonClass"
            aria-label="Vehicle Actions"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <loading
              :height="25"
              :opacity="0"
              color="#fff"
              v-if="lockReq"
              :active="true"
              loader="bars"
              :is-full-page="false"
            />
            <span v-if="!lockReq">
              <i v-if="isShowDropDown" class="mr-2 fas fa-chevron-down"></i>
              {{ getStatusText }}
            </span>
          </button>
        </template>

        <template v-if="isShowDropDown" v-slot:default="{ hide }">
          <div @click="hide()" class="py-1 mt-px bg-white rounded-md shadow-lg">
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles')"
              class="font-medium text-14px"
              @click="handleVehicleLockUnlock"
            >
              {{
                status === true
                  ? $t('components.vehicleManagement.itemActions.unlock')
                  : $t('components.vehicleManagement.itemActions.lock')
              }}
            </t-dropdown-item>
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles')"
              class="font-medium text-14px"
              @click="handleVehicleHibernateActivate"
            >
              {{
                hibernateStatus === true
                  ? $t('components.vehicleManagement.itemActions.activate')
                  : $t('components.vehicleManagement.itemActions.hibernate')
              }}
            </t-dropdown-item>

            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles')"
              class="font-medium text-14px"
              @click="forceSync"
            >
              {{
                $t('components.vehicleManagement.itemActions.forceSyncStatus')
              }}
            </t-dropdown-item>
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles') && canTakeVehicleUndockAction"
              class="font-medium text-14px flex justify-between"
              @click="handleUndockChargingAdapter"
            >
              {{
                $t(
                  'components.vehicleManagement.itemActions.undockChargingAdapter'
                )
              }}
            </t-dropdown-item>
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles') && canTakeVehicleUndockAction"
              class="font-medium text-14px flex justify-between"
              @click="syncDockStatus"
            >
              {{
                $t('components.vehicleManagement.itemActions.syncDockStatus')
              }}
            </t-dropdown-item>
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles') && canTakeInspectionAction"
              class="font-medium text-14px flex justify-between"
              @click="handleInspectionNeeded"
            >
              {{
                $t('components.vehicleManagement.itemActions.inspectionNeeded')
              }}

              <span
                class="ml-2 bg-oPurple px-2 py-1 rounded-lg text-xs text-oWhite "
                >BETA</span
              >
            </t-dropdown-item>
            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles') && canTakeRepairAction"
              class="font-medium text-14px flex justify-between"
              @click="handleRepairNeeded"
            >
              {{ $t('components.vehicleManagement.itemActions.repairNeeded') }}
              <span
                class="ml-2 bg-oPurple px-2 py-1 rounded-lg text-xs text-oWhite "
                >BETA</span
              >
            </t-dropdown-item>

            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles') && canTakeSwapBatteryAction"
              class="font-medium text-14px flex justify-between"
              @click="handleSwapBatteryNeeded"
            >
              {{
                $t('components.vehicleManagement.itemActions.swapBatteryNeeded')
              }}
              <span
                class="ml-2 bg-oPurple px-2 py-1 rounded-lg text-xs text-oWhite "
                >BETA</span
              >
            </t-dropdown-item>

            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles')"
              class="font-medium text-14px"
              @click="triggerRing"
            >
              {{ $t('components.vehicleManagement.itemActions.triggerRing') }}
            </t-dropdown-item>

            <t-dropdown-item
              v-if="$acl.canView('vehicles')"
              class="font-medium text-14px"
              @click="downloadQRCode"
            >
              {{
                $t('components.vehicleManagement.itemActions.downloadQrcode')
              }}
            </t-dropdown-item>

            <t-dropdown-item
              v-if="$acl.canUpdate('vehicles')"
              class="font-medium text-14px"
              @click="onEditVehicle"
            >
              {{ $t('components.vehicleManagement.itemActions.editVehicle') }}
            </t-dropdown-item>

            <t-dropdown-item
              v-if="$acl.canDelete('vehicles')"
              class="font-medium text-14px"
              variant="danger"
              @click="askConfirmation"
            >
              {{ $t('components.vehicleManagement.itemActions.deleteVehicle') }}
            </t-dropdown-item>
          </div>
        </template>
      </t-dropdown>
    </div>

    <ConfirmFirst
      :id="`confirmFirst-${vehicleId}`"
      :key="`confirmFirst-${vehicleId}`"
      :is-loading="isDeleting"
      :title="$t('components.vehicleManagement.itemActions.deleteTheVehicle')"
      :subtitle="
        $t('components.vehicleManagement.itemActions.actionCannotBeUndone')
      "
      :criticalityLevel="`medium`"
      :criticalityTitle="
        $t('components.vehicleManagement.itemActions.typeDeletionCode')
      "
      :criticalityPassword="`ILOVEOTORIDE`"
      :cancelButtonText="$t('components.vehicleManagement.itemActions.cancel')"
      :confirm-button-text="
        $t('components.vehicleManagement.itemActions.delete')
      "
      @confirm="onDeleteConfirm"
      @cancel="onDeleteCancel"
    />

    <DownloadQRCode
      :id="`downloadQRCode-${vehicleId}`"
      :key="`downloadQRCode-${vehicleId}`"
      :code="qrCode"
    />
    <VehicleInspection :id="`vi-${currentItemIndex}`" :vehicleProfile="data" />
    <VehicleRepair :id="`vi-${currentItemIndex}`" :vehicleProfile="data" />
    <SwapBattery :id="`sb-${currentItemIndex}`" :vehicleProfile="data" />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import DownloadQRCode from './DownloadQRCode'
import { EventBus } from '@/utils/EventBus'
import ReconnectingWebsocket from 'reconnectingwebsocket'
import { SocketConfig } from '@/config/SocketConfig'
import VehicleInspection from './Inspection/VehicleInspection.vue'
import { VehicleInspectionConfig } from '@/config/VehicleInspectionConfig'
import { VehicleSwapBatteryConfig } from '@/config/VehicleSwapBatteryConfig.js'

import { VehicleRepairConfig } from '@/config/VehicleRepairConfig'
import VehicleRepair from '@/composites/vehicle/shared/Repair/VehicleRepair.vue'
import SwapBattery from '@/composites/vehicle/shared/swap-battery/SwapBattery.vue'
import { xMan } from '@/utils'
export default {
  name: 'VehicleMoreActions',

  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    ConfirmFirst: () => import('@/components/modals/ConfirmFirst'),
    DownloadQRCode,
    VehicleInspection,
    VehicleRepair,
    SwapBattery,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    vehicleType: {
      type: [String, Number],
      required: false,
      default: 'scooter',
    },
    currentItemIndex: {
      type: Number,
      required: true,
      default: 0,
    },

    currentStatus: {
      type: Boolean,
      reqired: false,
    },
    hibernateStatus: {
      type: Boolean,
      reqired: false,
    },
    redirectPath: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'badge',
      validator(v) {
        return ['badge', 'emphasize'].includes(v)
      },
    },
    isShowDropDown: {
      type: Boolean,
      default: true,
    },
    takeActionFrom: {
      type: String,
      default: 'vehicle-list',
    },
  },

  watch: {
    currentStatus: {
      immediate: true,
      deep: false,
      handler(v) {
        this.status = v
      },
    },
  },

  mounted() {
    this.startListener()
  },

  data() {
    return {
      lockReq: false,
      isDeleting: false,
      sockListener: null,
      status: false,
      sendLockRequest: false,
      sendUndockRequest: false,
      timeoutId: null,
      startInspectionStatus: [
        'NOT_REQUIRED',
        'REQUIRED',
        'RE_INSPECTION',
        'UNDER_INSPECTION',
      ],
      startRepairStatus: ['REPAIR_REQUIRED', 'REPAIR_BACKLOG'],
      startSwapBatteryStatus: ['REQUIRED', 'SWAP_IN_PROGRESS'],
    }
  },
  beforeDestroy() {
    this.removeListener()
  },

  computed: {
    canTakeInspectionAction() {
      if (
        this.startInspectionStatus.includes(
          this.data.inspection_settings.inspection_status
        )
      ) {
        return true
      } else {
        return false
      }
    },
    canTakeRepairAction() {
      if (
        this.startRepairStatus.includes(
          this.data.inspection_settings.inspection_status
        )
      ) {
        return true
      } else {
        return false
      }
    },
    canTakeSwapBatteryAction() {
      if (
        this.startSwapBatteryStatus.includes(
          this.data.battery_settings.battery_swap_status
        )
      ) {
        return true
      } else {
        return false
      }
    },
    canTakeVehicleUndockAction() {
      return (
        this.data?.charging_adapter &&
        this.data?.charging_adapter?.docking_status !== 'UNDOCKED'
      )
    },
    vehicleId() {
      return this.data?.id
    },

    lockImei() {
      return this.data?.lock?.lock_id
    },

    qrCode() {
      return this.data?.qr_code
    },

    heartbeat() {
      return this.data?.heart_beat
    },

    getStatusText() {
      return this.status === true
        ? this.$t('components.vehicleManagement.itemActions.locked')
        : this.$t('components.vehicleManagement.itemActions.unlocked')
    },

    getButtonClass() {
      let c = 'vehicle-action-button '

      // variant
      c += `${this.variant} `

      // color
      if (this.heartbeat === false) {
        c += 'inactive '
      } else {
        c += this.status === true ? 'locked ' : 'unlocked '
      }

      return c
    },
  },

  methods: {
    async handleInspectionNeeded() {
      if (this.lockReq === true) {
        return
      }
      this.lockReq = true
      const url = useEndpoints.operationPrimer.inspectionAction.start()
      const data = new xMan({ vehicle_id: this.data.id }).toFormData()

      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: 'Processing, please wait...',
          text: 'The action is being executed.',
        },
        3000
      )

      this.$http
        .post(url, data)
        .then((res) => {
          EventBus.$emit(VehicleInspectionConfig.events.editingData, {
            inspectionId: res.data.id,
            parts: res.data.parts,
            inspectIndex: this.currentItemIndex,
            takeActionFrom: this.takeActionFrom,
          })

          dispatchEvent(
            new Event(
              VehicleInspectionConfig.events.sorOpen(
                `vi-${this.currentItemIndex}`
              )
            )
          )
        })
        .catch((err) => {
          console.log('start-inspection-err--', err.response.data.detail)
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Error Occurred [${err.response.status}]`,
            text: err.response.data.detail || 'Failed to start inspection',
          })
        })
        .finally(() => {
          this.lockReq = false
        })
    },
    async handleUndockChargingAdapter() {
      if (this.lockReq === true) {
        return
      }
      this.lockReq = true
      this.sendUndockRequest = true

      const url = useEndpoints.vehicle.chargingDockAdapter.undock(
        this.vehicleId
      )
      this.$http
        .patch(url)
        .then((res) => {
          console.log('handleUndockChargingAdapter', res)
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Processing, please wait...',
              text: `${res.data}`,
            },
            2000
          )
          this.getChargingDockStatus()
        })
        .catch((err) => {
          console.log('handleUndockChargingAdapter-err', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: `${err.response.data.message}`,
            },
            5000
          )
        })
    },
    handleRepairNeeded() {
      if (this.lockReq === true) {
        return
      }
      this.lockReq = true
      const url = useEndpoints.operationPrimer.repairAction.detail(
        this.vehicleId
      )

      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: 'Processing, please wait...',
          text: 'The action is being executed.',
        },
        3000
      )

      this.$http
        .get(url)
        .then((res) => {
          console.log('handleRepairNeeded', res)
          EventBus.$emit(VehicleRepairConfig.events.editingData, {
            inspectionId: res.data.id,
            parts: res.data.parts,
            repairIndex: this.currentItemIndex,
            takeActionFrom: this.takeActionFrom,
          })

          dispatchEvent(
            new Event(
              VehicleRepairConfig.events.sorOpen(`vi-${this.currentItemIndex}`)
            )
          )
        })
        .catch((err) => {
          console.log('start-inspection-err--', err.response.data.detail)
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Error Occurred [${err.response.status}]`,
            text: err.response.data.detail || 'Failed to start inspection',
          })
        })
        .finally(() => {
          this.lockReq = false
        })
    },
    handleSwapBatteryNeeded() {
      if (this.lockReq === true) {
        return
      }
      this.lockReq = true
      const url = useEndpoints.vehicle.swapBatteryWorkflow.start()
      const data = new xMan({ vehicle_id: this.data.id }).toFormData()

      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: 'Processing, please wait...',
          text: 'The action is being executed.',
        },
        1000
      )

      this.$http
        .post(url, data)
        .then((res) => {
          console.log(res)
          EventBus.$emit(VehicleSwapBatteryConfig.events.editingData, {
            swapBatteryIndex: this.currentItemIndex,
            workflowData: res.data,
          })
          dispatchEvent(
            new Event(
              VehicleSwapBatteryConfig.events.sorOpen(
                `sb-${this.currentItemIndex}`
              )
            )
          )
        })
        .catch((err) => {
          console.log('start-workflow-err--', err.response.data.detail)
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Error Occurred [${err.response.status}]`,
            text: err.response.data.detail || 'Failed to start workflow',
          })
        })
        .finally(() => {
          this.lockReq = false
        })
    },
    async handleVehicleLockUnlock() {
      // prevent if already req is made
      if (this.lockReq === true) {
        return
      }

      console.log(this.status)
      this.lockReq = true
      this.sendLockRequest = true
      const url =
        this.status === true
          ? useEndpoints.vehicle.status.forceUnlock(
              this.lockImei,
              this.vehicleType
            )
          : useEndpoints.vehicle.status.forceLock(
              this.lockImei,
              this.vehicleType
            )
      await this.$http
        .post(url)
        .then((res) => {
          console.log(res.data)
          this.getVehicleStatus()
        })
        .catch((error) => {
          this.lockReq = false
          console.log('error-v', { error })
          if (error.response.data.code && error.response.data.message) {
            if (error.response.data.code === 'vehicle_already_locked') {
              this.$store.commit('vehicleDetails/setDetails', {
                ...this.data,
                lock: {
                  ...this.data.lock,
                  is_locked: true,
                },
              })
              this.$store.commit('vehicleDetails/setLockStatus', {
                status: true,
              })
              this.$store.dispatch('fsTable/fetchData')
            }
            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: `Error! [${error.response.data.code}]`,
                text: error.response.data.message,
              },
              10000
            )
          } else {
            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: `Error! [${error.response.status}]`,
                text: error.response.data.detail ?? error.response.data,
              },
              10000
            )
          }
        })
    },

    async handleVehicleHibernateActivate() {
      const formData = new FormData()
      formData.append('hibernate_mode', this.hibernateStatus ? false : true)
      this.$http
        .patch(useEndpoints.vehicle.updateGeneralFLags(this.data.id), formData)
        .then((res) => {
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: 'Flag updated successfully',
            },
            5000
          )
          this.$store.dispatch('fsTable/fetchData')
          console.log('sc-', res)
        })
        .catch((err) => {
          console.log('general-err', err)
          this.$notify(
            {
              group: 'bottomRight',
              type: 'error',
              title: 'Failed',
              text: `${err.response}`,
            },
            5000
          )
        })
    },

    // async getVehicleStatus(delay = 10000) {
    //   setTimeout(async () => {
    //     console.log('wait...')

    //     await this.$http
    //       .get(useEndpoints.vehicle.status.current(this.vehicleId))
    //       .then((res) => {
    //         console.log(res.data)
    //         // update only if the lock status has changed
    //         if (res.data.locked !== this.status) {
    //           this.status = res.data.locked
    //           let mTitle = res.data.locked ? 'Locked' : 'Unlocked'
    //           this.$notify({
    //             group: 'bottomRight',
    //             type: 'success',
    //             title: mTitle,
    //             text: `The vehicle is now ${mTitle.toLowerCase()}`,
    //           })
    //           this.$emit('change', res.data.locked)
    //         } else {
    //           // action is the oposite of the current status
    //           let msg =
    //             this.status === true ? 'Failed to unlock!' : 'Failed to lock!'
    //           this.$notify({
    //             group: 'bottomRight',
    //             type: 'error',
    //             title: 'Failed!',
    //             text: `${msg} Please try again`,
    //           })
    //         }
    //       })
    //       .catch((err) => {
    //         console.warn(err)
    //         this.$notify({
    //           group: 'bottomRight',
    //           type: 'error',
    //           title: 'HTTP Error! [Lock]',
    //           text: `HTTP Error! Please try again`,
    //         })
    //       })
    //       .finally(() => (this.lockReq = false))
    //   }, delay)
    // },

    async forceSync() {
      // prevent if already req is made
      if (this.lockReq === true) {
        return
      }

      this.lockReq = true

      let url = useEndpoints.vehicle.status.forceSync(this.vehicleId)
      await this.$http
        .post(url)
        .then((res) => {
          console.warn({ forceSync: res })
          this.$emit('force-sync')
        })
        .catch((err) => {
          console.error({ forceSync: err.response })
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Error [${err.response.status}]`,
            text: `Unable to process the request!`,
          })
        })
      this.lockReq = false
    },
    async syncDockStatus() {
      // prevent if already req is made
      if (this.lockReq === true) {
        return
      }

      this.lockReq = true

      let url = useEndpoints.vehicle.chargingDockAdapter.dockingStatus(
        this.vehicleId
      )
      await this.$http
        .get(url)
        .then((res) => {
          this.$store.commit('vehicleDetails/setChargingDockStatus', {
            status: res.data.docking_status,
          })
          this.$emit('force-sync')
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: `Error [${err.response.status}]`,
            text: `Please try again`,
          })
        })
      this.lockReq = false
    },

    async triggerRing() {
      await this.$http
        .post(useEndpoints.vehicle.triggerRing(this.vehicleId), new FormData())
        .then(async () => {
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: `Alarm`,
              text: 'Ringing alarm...',
            },
            10000
          )
        })
        .catch((err) => {
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Server Error [${err.response.status}]`,
            text: 'Unable to process the request!',
          })
        })
    },

    async downloadQRCode() {
      this.$modal.show(`downloadQRCode-${this.vehicleId}`)
    },

    askConfirmation() {
      this.$modal.show(`confirmFirst-${this.vehicleId}`)
    },
    onDeleteCancel() {
      this.$modal.hide(`confirmFirst-${this.vehicleId}`)
    },
    async onDeleteConfirm() {
      console.log('redirectPath', this.redirectPath)
      const data = new FormData()
      data.append('with_trip', true)
      data.append('with_lock', true)

      this.isDeleting = true
      await this.$http
        .delete(useEndpoints.vehicle.delete(this.vehicleId), { data })
        .then(() => {
          this.$emit('delete:succeeded', this.data)
          this.$modal.hide(`confirmFirst-${this.vehicleId}`)
          this.$router.push(this.redirectPath)
          EventBus.$emit('force-refresh')
        })
        .catch((e) => {
          console.error(e.response)
          this.$emit('delete:failed', e.response)
          this.$modal.hide(`confirmFirst-${this.vehicleId}`)
        })
        .finally(() => (this.isDeleting = false))
    },
    onEditVehicle() {
      this.$emit('edit', { vehicleId: this.vehicleId })
    },
    startListener() {
      let token = localStorage.getItem('token') || null
      // console.log(`Fetched Token : ${token}`)
      if (!token) return
      let sockUrl =
        SocketConfig.baseUrl +
        SocketConfig.channels.bikeUpdates +
        `?token=${token}`

      this.sockListener = this.sockListener
        ? this.sockListener
        : new ReconnectingWebsocket(sockUrl, null, SocketConfig.configs())

      this.sockListener.onopen = function() {
        console.log('Opened Connection bikeUpdates channel...')
      }
      this.sockListener.onclose = function() {
        console.log('Closed Connection bikeUpdates channel...')
      }
      this.sockListener.onmessage = this.onMessageHandler
    },
    removeListener() {
      if (this.sockListener) {
        this.sockListener.close(
          4003,
          'oto system closed the socket to refresh stream'
        )
        this.sockListener = null
      }
    },
    onMessageHandler(message) {
      console.log('Incoming update', message, JSON.parse(message.data))
      let payload = JSON.parse(message.data)
      if (payload.n_type !== 'noti.bike_updates') return

      const sRes = payload.data
      console.log('Recieved Payload...', sRes)

      let bikeId = sRes['id']
      if (!bikeId) return
      if (this.data.id !== bikeId) return
      this.$store.commit('vehicleDetails/setLockStatus', {
        status: sRes.is_locked,
      })
      this.$store.commit('vehicleDetails/setFlags', {
        general_flags: sRes.general_flags,
        operational_flags: sRes.operational_flags,
        status_flags: sRes.status_flags,
      })
      if (this.status !== sRes.is_locked) {
        this.status = sRes.is_locked
        if (this.sendLockRequest) {
          const mTitle = this.status ? 'Locked' : 'Unlocked'
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: mTitle,
              text: `The vehicle is now ${mTitle.toLowerCase()}`,
            },
            3000
          )
          this.sendLockRequest = false
        }

        this.lockReq = false
        clearTimeout(this.timeoutId)
      }

      console.log('Incoming update current', message, JSON.parse(message.data))
    },
    async getVehicleStatus(delay = 20000) {
      this.timeoutId = setTimeout(async () => {
        console.log('wait...')

        if (this.lockReq && this.sendLockRequest) {
          await this.$http
            .get(useEndpoints.vehicle.status.current(this.vehicleId))
            .then((res) => {
              console.log(res.data)
              // update only if the lock status has changed
              if (res.data.locked !== this.status) {
                this.status = res.data.locked
                this.$store.commit('vehicleDetails/setLockStatus', {
                  status: res.data.locked,
                })
                this.$store.commit('vehicleDetails/setDetails', {
                  ...this.data,
                  lock: {
                    ...this.data.lock,
                    is_locked: res.data.locked,
                  },
                })

                let mTitle = res.data.locked ? 'Locked' : 'Unlocked'
                this.$notify(
                  {
                    group: 'bottomRight',
                    type: 'success',
                    title: mTitle,
                    text: `The vehicle is now ${mTitle.toLowerCase()}`,
                  },
                  2000
                )
              } else {
                // action is the oposite of the current status
                let msg =
                  this.status === true ? 'Failed to unlock!' : 'Failed to lock!'
                this.$notify(
                  {
                    group: 'bottomRight',
                    type: 'error',
                    title: 'Failed!',
                    text: `${msg} Please try again`,
                  },
                  3000
                )
              }
            })
            .catch((err) => {
              console.warn(err)
              this.$notify({
                group: 'bottomRight',
                type: 'error',
                title: 'HTTP Error!',
                text: `HTTP Error! Please try again`,
              })
            })
            .finally(() => {
              this.lockReq = false
              this.sendLockRequest = false
            })
        }
      }, delay)
    },
    async getChargingDockStatus(delay = 10000) {
      this.timeoutId = setTimeout(async () => {
        console.log('wait...')

        if (this.lockReq && this.sendUndockRequest) {
          await this.$http
            .get(
              useEndpoints.vehicle.chargingDockAdapter.dockingStatus(
                this.vehicleId
              )
            )
            .then((res) => {
              if (res.data.docking_status === 'UNDOCKED') {
                this.$store.commit('vehicleDetails/setChargingDockStatus', {
                  status: res.data.docking_status,
                })
                this.$notify(
                  {
                    group: 'bottomRight',
                    type: 'success',
                    title: 'Success',
                    text: `The vehicle is now undocked`,
                  },
                  2000
                )
              } else {
                this.$notify(
                  {
                    group: 'bottomRight',
                    type: 'error',
                    title: 'Failed!',
                    text: `Failed to Undock! Please try again`,
                  },
                  3000
                )
              }
            })
            .catch((err) => {
              console.warn(err)
              this.$notify({
                group: 'bottomRight',
                type: 'error',
                title: 'HTTP Error!',
                text: `HTTP Error! Please try again`,
              })
            })
            .finally(() => {
              this.lockReq = false
              this.sendUndockRequest = false
            })
        }
      }, delay)
    },
  },
}
</script>

<style lang="scss" scoped>
.vehicle-action-button {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  font-size: 14px;
  font-weight: 500;

  &:focus {
    outline: none;
  }

  // colors
  &.locked {
    @apply bg-oGreen;
  }
  &.unlocked {
    @apply bg-oPurple;
  }
  &.inactive {
    background-color: #c7c7c7;
  }

  // variants
  &.badge {
    height: 20px;
    border-radius: 3px;
  }

  &.emphasize {
    width: 155px;
    @apply flex items-center justify-between shadow-md rounded-full h-35px;
    @apply px-3 py-0 pl-3 text-center cursor-pointer;

    &.inactive {
      @apply bg-gray-300;
    }
  }
}
@media screen and (max-width: 640px) {
  .badge {
    width: 92px;
  }
}
@media screen and (min-width: 641px) {
  .badge {
    width: 110px;
  }
}
</style>
